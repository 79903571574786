import React from 'react';
import { Box, Typography, Button, List, ListItem } from '@mui/material';

const Tree = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Se conecte conosco!
      </Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
        <ListItem sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            href="https://www.instagram.com/casa_celulares/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ width: '200px', borderRadius: '20px', padding: '10px 20px', fontSize: '16px' }}
          >
            Instagram
          </Button>
        </ListItem>
        <ListItem sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="success"
            href="https://wa.me/+5549998315886"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ width: '200px', borderRadius: '20px', padding: '10px 20px', fontSize: '16px' }}
          >
            WhatsApp
          </Button>
        </ListItem>
      </List>
    </Box>
  );
};

export default Tree;
