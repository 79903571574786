import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h3" gutterBottom>
        Casa dos Celulares
      </Typography>
      <Typography variant="body1" gutterBottom>
        Visite nossa loja para acessórios e manutenção de celulares na Casa dos Celulares, localizada no coração da cidade!
      </Typography>

      <Box sx={{ margin: '20px 0' }}>
        <Typography variant="h5" gutterBottom>
          Nos visite
        </Typography>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1676.2046204942355!2d-53.399471443068975!3d-27.101047966033295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94fba8187ad9e2a7%3A0x7aca4fab75e58fa4!2sCASA%20DOS%20CELULARES!5e0!3m2!1spt-BR!2sbr!4v1729615771601!5m2!1spt-BR!2sbr"
          width="600"
          height="450"
          allowFullScreen
          loading="lazy"
          style={{ border: 'none' }}
        ></iframe>
      </Box>

      <Box sx={{ margin: '20px 0' }}>
        <Typography variant="h6" gutterBottom>
          Informações da loja
        </Typography>
        <Typography variant="body2">Endereço: Av. Lajú, 772 - Centro, Mondaí - SC, 89893-000</Typography>
        <Typography variant="body2">Atendimento: Segunda - Sexta, 8:00 - 12:00 / 13:30 - 18:00</Typography>
        <Typography variant="body2">Contato: +55 49 99831-5886</Typography>
      </Box>

      <Button variant="contained" component={Link} to="/tree">
        Nos siga
      </Button>
    </Box>
  );
};

export default Home;
